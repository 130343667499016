import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import LandingPage from '~/components/Gift/LandingPage'
import CustomErrorPage from '~/components/Gift/CustomErrorPage'
import CustomLoading from '~/components/Gift/CustomLoading'
import GiftPage from '~/components/Gift/GiftPage'
import Metadata from '~/components/Metadata'
import Layout from '~/components/Layout'
import GiftFlowFeatureFlag from '~/components/Gift/GiftFlowFeatureFlag'
import { getGift } from '~/utils/get-gift'
import { getStates } from '~/utils/get-states'
import ErrorNotFoundPage from '~/components/Error/NotFound/Page'

const DEFAULT_COUNTRY_ISO = 'US'

const GiftFrom = ({ params: { slug }, location }) => {
  const [currentPage, setCurrentPage] = useState('landing')
  const [states, setStates] = useState([])
  const [gift, setGift] = useState(null)
  const [loading, setLoading] = useState(true)
  const [giftError, setGiftError] = useState(null)

  const updateCurrentPage = pageName => {
    setCurrentPage(pageName)
    return window.scrollTo(0, 0)
  }

  const getStatesData = async countryIso => {
    const statesData = await getStates(countryIso)
    setStates(statesData ?? [])
  }

  const getGiftFlowData = async () => {
    const giftData = await getGift(slug)

    giftData?.gift_details?.active && giftData?.products_data.length > 0
      ? setGift(giftData)
      : setGiftError(Number(giftData.status))

    setLoading(false)
  }

  useEffect(() => {
    getGiftFlowData()
    getStatesData(DEFAULT_COUNTRY_ISO)
  }, [])

  return (
    <Layout>
      <Metadata title="You Got a Gift!" />
      <GiftFlowFeatureFlag location={location}>
        {loading ? (
          <CustomLoading />
        ) : giftError && giftError === 406 ? (
          <CustomErrorPage />
        ) : giftError && giftError === 404 ? (
          <ErrorNotFoundPage />
        ) : currentPage === 'landing' ? (
          <LandingPage
            fakeNavigateTo={updateCurrentPage}
            gifterName={gift?.gift_details?.gifters_name}
            gifterAvatar={gift?.gift_details?.avatar_url}
          />
        ) : (
          <GiftPage
            slug={slug}
            fakeNavigateTo={updateCurrentPage}
            states={states}
            gift={gift}
          />
        )}
      </GiftFlowFeatureFlag>
    </Layout>
  )
}

GiftFrom.propTypes = {
  params: PropTypes.object,
  location: PropTypes.object,
}

export default GiftFrom
